import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { UnitTableWidget } from 'bundles/Shared/widgets/dashboard/widgets/unitTable/ui/UnitTableWidget';
import {
  GridOptions,
  ICellRendererParams,
  ISetFilterParams,
} from 'ag-grid-community';
import { BasicCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import { Label } from '@/stories';

import { formatUnitStatus } from '@/entities/report/unitTypes/lib';
import { RENTROLL_UNIT_STATUS_COLORS } from '@/entities/report/unitTypes/config';
import { getDefaultAgGridNumberColDef } from '@/shared/lib/formatting/table';
import { convertCentsToDollars } from '@/shared/lib/converters';
import { SetFilterComponent } from 'bundles/Shared/components/AgGrid/Table/filters/SetFilterComponent';
import { MasterUnitTableDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import { TRentrollUnitStatus } from '@/entities/report/unitTypes/model';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { startCase } from 'lodash-es';

export const MASTER_UNIT_TABLE_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 13,
    maxH: 12,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Master Unit Table',
  icon: 'table',
  Component: UnitTableWidget,
} as const satisfies WidgetConfiguration;

const UNIT_STATUS_CELL_RENDERER = (params: ICellRendererParams) => (
  <BasicCellRenderer {...params}>
    <Label
      color={RENTROLL_UNIT_STATUS_COLORS[params.value as TRentrollUnitStatus]}
    >
      {params.valueFormatted}
    </Label>
  </BasicCellRenderer>
);

const unitStatusValueFormatter = (params: ValueFormatterParams) =>
  formatUnitStatus(params.value);

export const LEASE_DETAILS_MODAL_DEFAULT_AG_GRID_CONFIG = {
  columnDefs: [
    {
      colId: 'residentName',
      headerName: 'Resident Name',
      field: 'residentName',
    },
    {
      groupId: 'general',
      headerName: 'General',
      headerGroupComponentParams: {
        style: {
          backgroundColor: 'rgba(11, 23, 48, 1)',
        },
      },
      children: [
        {
          headerName: 'Trade Out',
          colId: 'tradeOut',
          field: 'tradeoutCents',
          valueGetter: ({ data }) => convertCentsToDollars(data.tradeoutCents),
          ...getDefaultAgGridNumberColDef({
            type: 'currency',
          }),
        },
        {
          headerName: 'Lease Rent',
          colId: 'actualRent',
          field: 'actualRentCents',
          valueGetter: ({ data }) =>
            convertCentsToDollars(data.actualRentCents),
          ...getDefaultAgGridNumberColDef({
            type: 'currency',
          }),
        },
        {
          headerName: 'Lease Type',
          colId: 'leaseType',
          field: 'leaseType',
          valueFormatter: (params) => startCase(params.value),
        },
        {
          headerName: 'Lease Status',
          colId: 'leaseStatus',
          field: 'status',
        },
      ],
    },
    {
      groupId: 'moveDetails',
      headerName: 'Move Date',
      headerGroupComponentParams: {
        style: {
          backgroundColor: 'rgba(8, 80, 128, 1)',
          borderColor: 'rgba(22, 139, 221, 1)',
        },
      },
      children: [
        {
          headerName: 'Move-In',
          colId: 'moveIn',
          field: 'moveInDate',
          ...getDefaultAgGridNumberColDef({
            type: 'dateString',
          }),
        },
        {
          headerName: 'Move Out',
          colId: 'moveOut',
          field: 'moveOutDate',
          ...getDefaultAgGridNumberColDef({
            type: 'dateString',
          }),
        },
      ],
    },
    {
      groupId: 'leaseDetails',
      headerName: 'Lease Details',
      headerGroupComponentParams: {
        style: {
          backgroundColor: 'rgba(58, 29, 106, 1)',
          borderColor: 'rgba(96, 64, 169, 1)',
        },
      },
      children: [
        {
          headerName: 'Term',
          colId: 'leaseTerm',
          field: 'termMonths',
        },
        {
          headerName: 'Signed Date',
          colId: 'signDate',
          field: 'signDate',
          ...getDefaultAgGridNumberColDef({
            type: 'dateString',
          }),
        },
        {
          headerName: 'Start Date',
          colId: 'startDate',
          field: 'startDate',
          initialSort: 'desc',
          ...getDefaultAgGridNumberColDef({
            type: 'dateString',
          }),
        },
        {
          headerName: 'End Date',
          colId: 'endDate',
          field: 'endDate',
          ...getDefaultAgGridNumberColDef({
            type: 'dateString',
          }),
        },
      ],
    },
    {
      groupId: 'additional',
      headerName: 'Additional',
      headerGroupComponentParams: {
        style: {
          backgroundColor: 'rgba(11, 23, 48, 1)',
          borderColor: 'rgba(33, 42, 47, 1)',
        },
      },
      // balance, deposit
      children: [
        {
          headerName: 'Balance',
          colId: 'balance',
          field: 'balanceCents',
          valueGetter: ({ data }) => convertCentsToDollars(data.balanceCents),
          ...getDefaultAgGridNumberColDef({
            type: 'currency',
          }),
        },
        {
          headerName: 'Deposit',
          colId: 'deposit',
          field: 'totalDepositCents',
          valueGetter: ({ data }) =>
            convertCentsToDollars(data.totalDepositCents),
          ...getDefaultAgGridNumberColDef({
            type: 'currency',
          }),
        },
      ],
    },
  ],
} satisfies Partial<GridOptions>;

export const getMasterUnitTableColumnDefs = (
  filterOptions: MasterUnitTableDto['filterOptions'],
): Partial<GridOptions> => {
  const getFilterOptions = (column: string) =>
    filterOptions?.find((o) => o.column === column)?.options;
  return {
    columnDefs: [
      {
        groupId: 'general',
        headerName: 'General',
        headerGroupComponentParams: {
          style: {
            backgroundColor: 'rgba(11, 23, 48, 1)',
          },
        },
        children: [
          {
            headerName: 'Square Feet',
            colId: '0',
          },
          {
            headerName: 'Floorplan',
            colId: '1',
          },
          {
            headerName: 'Unit Type',
            colId: '2',
            filter: SetFilterComponent,
            filterParams: {
              values: getFilterOptions('Unit Type'),
            },
            headerComponentParams: {
              style: {
                backgroundColor: 'rgba(11, 23, 48, 1)',
              },
              classes: {
                subHeader: 'hidden',
              },
            },
          },
        ],
      },
      {
        groupId: 'leasedStatus',
        headerName: 'Leased Status',
        headerGroupComponentParams: {
          style: {
            backgroundColor: 'rgba(58, 29, 106, 1)',
          },
          classes: {
            subHeader: 'hidden',
          },
        },
        children: [
          {
            headerName: 'Current',
            colId: '3',
            cellRenderer: UNIT_STATUS_CELL_RENDERER,
            valueFormatter: unitStatusValueFormatter,
            filter: SetFilterComponent,
            filterParams: {
              values: getFilterOptions('Leased Status'),
              valueFormatter: unitStatusValueFormatter,
            } satisfies ISetFilterParams,
          },
        ],
      },
      {
        groupId: 'physical',
        headerName: 'Physical',
        headerGroupComponentParams: {
          style: {
            backgroundColor: 'rgba(19, 137, 117, 1)',
            borderColor: 'rgba(180, 225, 218, 1)',
          },
          classes: {
            subHeader: '!text-teal-020',
          },
        },
        children: [
          {
            headerName: 'Current',
            colId: '4',
            cellRenderer: UNIT_STATUS_CELL_RENDERER,
            valueFormatter: unitStatusValueFormatter,
          },
          {
            headerName: '+30 days',
            colId: '5',
          },
          {
            headerName: '+60 days',
            colId: '6',
          },
          {
            headerName: '+90 days',
            colId: '7',
          },
        ],
      },
      {
        groupId: 'leaseDetails',
        headerName: 'Lease Details (Current Tenant)',
        headerGroupComponentParams: {
          style: {
            backgroundColor: 'rgba(3, 40, 98, 1)',
            borderColor: 'rgba(56, 83, 169, 1)',
          },
        },
        children: [
          {
            headerName: 'Resident Name',
            colId: '8',
          },
          {
            headerName: 'Move In',
            colId: '9',
            ...getDefaultAgGridNumberColDef({
              type: 'dateString',
            }),
          },
          {
            headerName: 'Lease Expiration',
            colId: '10',
            ...getDefaultAgGridNumberColDef({
              type: 'dateString',
            }),
          },
          {
            headerName: 'Move Out',
            colId: '11',
            ...getDefaultAgGridNumberColDef({
              type: 'dateString',
            }),
          },
        ],
      },
      {
        groupId: 'rentFees',
        headerName: 'Rent & Fees (Current Tenant)',
        headerGroupComponentParams: {
          style: {
            backgroundColor: 'rgba(11, 98, 79, 1)',
            borderColor: 'rgba(19, 137, 117, 1)',
          },
        },
        children: [
          {
            headerName: 'Market Rent',
            colId: '12',
          },
          {
            headerName: 'Asking Rent',
            colId: '13',
          },
          {
            headerName: 'Lease Rent',
            colId: '14',
          },
          {
            headerName: 'Trade Out',
            colId: '15',
          },
          {
            headerName: 'Rent PSF',
            colId: '16',
          },
          {
            headerName: 'Balance',
            colId: '17',
          },
          {
            headerName: 'Parking Fee',
            colId: '18',
          },
          {
            headerName: 'Pet Fee',
            colId: '19',
          },
          {
            headerName: 'Storage Fee',
            colId: '20',
          },
          {
            headerName: 'Laundry Fee',
            colId: '21',
          },
          {
            headerName: 'Utilities Fee',
            colId: '22',
          },
          {
            headerName: 'Leasing Fee',
            colId: '23',
          },
          {
            headerName: 'Penalty Fee',
            colId: '24',
          },
          {
            headerName: 'Misc. Fee',
            colId: '25',
          },
          {
            headerName: 'Concessions',
            colId: '26',
          },
        ],
      },
      {
        groupId: 'futureTenant',
        headerName: 'Future Tenant',
        headerGroupComponentParams: {
          style: {
            backgroundColor: 'rgba(8, 80, 128, 1)',
            borderColor: 'rgba(22, 139, 221, 1)',
          },
        },
        children: [
          {
            headerName: 'Name',
            colId: '27',
          },
          {
            headerName: 'Application Rent',
            colId: '28',
          },
          {
            headerName: 'Move In',
            colId: '29',
            ...getDefaultAgGridNumberColDef({
              type: 'dateString',
            }),
          },
        ],
      },
    ],
  };
};
