import {
  convertDecimalToPercentage,
  convertToBps,
} from '@/shared/lib/converters';
import { formatDate } from '@/shared/lib/formatting/dates';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/number';
import {
  CurrencyFormatter,
  MetricNumberFormatter,
  PercentFormatter,
} from '@/stories';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { BasicCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import { merge } from 'lodash-es';
import React, { CSSProperties } from 'react';
import BpsNumberFormatter from 'stories/ValueFormatters/BpsNumberFormatter';
import { NumberFormatter } from 'stories/ValueFormatters/NumberFormatter';
import SqftNumberFormatter from 'stories/ValueFormatters/SqftNumberFormatter';
import { UnknownRecord } from 'type-fest/source/internal';

export type CellRenderers =
  | 'number'
  | 'percentage'
  | 'currency'
  | 'sqft'
  | 'metric'
  | 'dateString'
  | 'bps'
  | 'string';
type CellRenderersWithoutNumber = Exclude<
  CellRenderers,
  'number' | 'dateString' | 'string'
>;
// todo move to lib scoreboard
export const DISPLAY_TYPE_NUMBER_FORMATTERS = {
  currency: CurrencyFormatter,
  percentage: PercentFormatter,
  metric: MetricNumberFormatter,
  sqft: SqftNumberFormatter,
  number: NumberFormatter,
  bps: BpsNumberFormatter,
} as const satisfies Record<
  Exclude<CellRenderers, 'dateString' | 'string'>,
  React.FC
>;
export const getNumberFormatterByDisplayType = ({
  precision,
  displayType,
  hideCommaSeparator,
}: {
  precision: number;
  displayType: CellRenderers;
  hideCommaSeparator?: boolean;
}) => {
  if (displayType === 'string') {
    return ({ value }: { value: number | null }) => <>{value}</>;
  }
  if (displayType === 'dateString') {
    return ({ value }: { value: number | null }) => (
      <>{value ? formatDate(value, 'MM-DD-YYYY') : DEFAULT_NUMBER_FALLBACK}</>
    );
  }
  const Component = DISPLAY_TYPE_NUMBER_FORMATTERS[displayType];

  return ({
    value,
    ...props
  }: { value: number | null } & React.ComponentProps<
    (typeof DISPLAY_TYPE_NUMBER_FORMATTERS)[CellRenderersWithoutNumber]
  >) => {
    // console.log('props:', props);
    const getValue = () => {
      if (displayType === 'percentage') {
        return value ? convertDecimalToPercentage(value) : value;
      }
      if (displayType === 'bps') {
        return value ? convertToBps(value) : value;
      }
      return value;
    };
    return (
      <Component
        value={getValue()}
        toLocalStringOptions={{
          minimumFractionDigits: precision,
          maximumFractionDigits: precision,
          useGrouping: !hideCommaSeparator,
        }}
        {...props}
      />
    );
  };
};
type StyledBasicCellRendererProps = {
  styles?: CSSProperties;
  labelColor?: string;
} & ICellRendererParams;

const StyledBasicCellRenderer = ({
  styles,
  labelColor,
  children,
  ...params
}: React.PropsWithChildren<StyledBasicCellRendererProps>) => {
  const Wrapper = labelColor
    ? ({ children: wrapperChildren }: React.PropsWithChildren) => (
        <div
          style={{
            background: labelColor,
          }}
          className="!rounded-lg px-tw-2 pt-tw-0.5"
        >
          {wrapperChildren}
        </div>
      )
    : React.Fragment;
  return (
    <BasicCellRenderer
      styles={{
        wrapper: styles,
      }}
      {...params}
    >
      <Wrapper>{children ?? params.valueFormatted ?? params.value}</Wrapper>
    </BasicCellRenderer>
  );
};
export const getDefaultAgGridNumberColDef = ({
  precision = 0,
  type = 'number',
  kilo_formatting,
  hide_comma_separator,
  ...params
}: {
  precision?: number;
  type?: CellRenderers;
  kilo_formatting?: boolean;
  hide_comma_separator?: boolean;
  fallbackCondition?: (value: number) => boolean;
}): ColDef => {
  const fallbackCondition =
    params.fallbackCondition ?? ((value: number) => value === 0);
  if (type === 'dateString') {
    return {
      type,
      minWidth: 110,
      cellRenderer: StyledBasicCellRenderer,
      valueFormatter: ({ value }) =>
        value ? formatDate(value, 'MM-DD-YYYY') : DEFAULT_NUMBER_FALLBACK,
    };
  }
  if (type === 'string') {
    return {
      type,
      cellRenderer: StyledBasicCellRenderer,
    };
  }

  const Component = getNumberFormatterByDisplayType({
    precision,
    displayType: type,
    hideCommaSeparator: hide_comma_separator,
  });
  return {
    type,
    cellRenderer: ({
      styles,
      ...cellRendererParams
    }: StyledBasicCellRendererProps & {
      formatterParams: UnknownRecord;
    }) => {
      const inheritedFormatterParams = {
        styles: {
          allParts: {
            color: styles?.color,
          },
        },
      };

      const finalFormatterParams = merge(
        inheritedFormatterParams,
        cellRendererParams.formatterParams,
      );

      return (
        <StyledBasicCellRenderer styles={styles} {...cellRendererParams}>
          {fallbackCondition(cellRendererParams.value) ? (
            DEFAULT_NUMBER_FALLBACK
          ) : (
            <Component
              value={cellRendererParams.value}
              abbreviate={kilo_formatting}
              fallbackValue={DEFAULT_NUMBER_FALLBACK}
              {...finalFormatterParams}
            />
          )}
        </StyledBasicCellRenderer>
      );
    },
  };
};
