import { LegalEntity } from '@/entities/core/legalEntity';
import { DEFAULT_LAYOUT_PROPS } from 'bundles/Shared/widgets/dashboard/layout';
import { currentUserAllowedTo, TProductNames } from 'lib/permissions';
import { cloneDeep, maxBy, uniqBy } from 'lodash-es';
import { createContext, useContext } from 'react';
import { Layout, utils } from 'react-grid-layout';
import {
  COMPARISON_DASHBOARD_SECTION_TYPE,
  DashboardFilterObjectTypes,
  OBJECT_DASHBOARD_SECTION_TYPE,
  ReportComparisonDashboardSection,
  ReportDashboardEagleEyeAsset,
  ReportDashboardLegalEntity,
  ReportDashboardSectionPosition,
  ReportDashboardSectionPositionWithId,
  ReportDashboardType,
  ReportObjectDashboardSection,
} from './model';

export const DEFAULT_SECTION_LAYOUT = {
  minW: 1,
  maxW: 2,
  minH: 12,
  maxH: 12,
  w: 1,
  h: 12,
} as const satisfies Omit<ReportDashboardSectionPosition, 'x' | 'y'>;

const getSectionXPositionByIndex = (index: number) =>
  index % DEFAULT_LAYOUT_PROPS.cols!.lg;
const getSectionYPositionByIndex = (index: number) =>
  DEFAULT_SECTION_LAYOUT.h * Math.floor(index / DEFAULT_LAYOUT_PROPS.cols!.lg);

export const getSectionLayoutByIndex = (
  index: number,
): ReportDashboardSectionPosition => ({
  x: getSectionXPositionByIndex(index),
  y: getSectionYPositionByIndex(index),
  ...DEFAULT_SECTION_LAYOUT,
});

export const isObjectDashboardSectionEditable = (
  section: ReportObjectDashboardSection,
): boolean =>
  section.widgetType ===
    OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE ||
  section.widgetType ===
    OBJECT_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE ||
  section.widgetType === OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD ||
  section.widgetType === OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART ||
  section.widgetType ===
    OBJECT_DASHBOARD_SECTION_TYPE.AVERAGE_ACTUAL_RENT_CHART;

export const isComparisonDashboardSectionEditable = (
  section: ReportComparisonDashboardSection,
): boolean =>
  section.widgetType ===
    COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_DATE ||
  section.widgetType ===
    COMPARISON_DASHBOARD_SECTION_TYPE.FINANCIAL_TABLE_SINGLE_PERIOD;

export const getSectionExpandedLayout = (
  layout: ReportDashboardSectionPosition,
): ReportDashboardSectionPosition => ({
  ...layout,
  w:
    layout.w === DEFAULT_LAYOUT_PROPS.cols!.lg
      ? 1
      : DEFAULT_LAYOUT_PROPS.cols!.lg,
});

export const getLegalEntitiesFromAssets = (
  assets: ReportDashboardEagleEyeAsset[],
): ReportDashboardLegalEntity[] =>
  uniqBy(
    assets.flatMap((asset) => asset.legalEntities),
    'id',
  );

export const filterIncludedLegalEntities = <L extends Pick<LegalEntity, 'id'>>(
  le: L,
  excludedLegalEnitityIds: L['id'][],
): boolean => {
  return !excludedLegalEnitityIds.includes(le.id);
};

const compactLayout = (layout: Layout[]): Layout[] => {
  // use clone deep to avoid mutation
  return utils.compact(cloneDeep(layout), 'vertical', 2).map((s) => {
    const initialLayoutItem = layout.find((l) => l.i === s.i);
    return {
      ...initialLayoutItem,
      ...s,
    };
  });
};

/**
 * @param layout - the layout of the dashboard
 * @param sections - the sections to add to the layout
 * @param options - the options for the layout
 * @param [options.toTheEnd=true] - if true, the sections will be added to the end of the layout
 * @returns the new layout
 */
export const addSectionsToLayout = (
  layout: ReportDashboardSectionPositionWithId[],
  sections: (Omit<ReportDashboardSectionPositionWithId, 'x' | 'y'> &
    Partial<Pick<ReportDashboardSectionPositionWithId, 'x' | 'y'>>)[],
  {
    toTheEnd = true,
  }: {
    toTheEnd?: boolean;
  } = {},
): ReportDashboardSectionPositionWithId[] => {
  const maxY = maxBy(layout, (item) => item.y)?.y ?? -1;
  const endPosition = toTheEnd
    ? {
        x: 0,
        y: maxY + 1,
      }
    : {};
  // use clone deep to avoid mutation
  // compact util deletes custom properties, so we need to add them back
  return compactLayout([
    ...layout,
    ...sections.map((s) => ({
      ...s,
      ...endPosition,
    })),
  ] as Layout[]) as ReportDashboardSectionPositionWithId[];
};

export const removeSectionFromLayout = (
  layout: ReportDashboardSectionPositionWithId[],
  sectionId: string,
): ReportDashboardSectionPositionWithId[] =>
  compactLayout(
    layout.filter((l) => l.i !== sectionId),
  ) as ReportDashboardSectionPositionWithId[];

export type DashboardContextValue = {
  dashboardId: string;
  dashboardType: ReportDashboardType;
  boardId: string;
};

export const DashboardContext = createContext<DashboardContextValue>({
  dashboardId: '',
  dashboardType: ReportDashboardType.OBJECT,
  boardId: '',
});

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};

export const DASHBOARD_OBJECT_TYPES = [
  'asset',
  'fund',
  'segment',
] as const satisfies DashboardFilterObjectTypes[];

export const canConfigureDashboard = () => {
  return currentUserAllowedTo('configure', TProductNames.REPORT);
};
