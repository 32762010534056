import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { GlobalLeaseTableWidget } from 'bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/widget';
import Icon from 'stories/Icon/Icon';
import { BasicCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import { CELL_CLASS_NAMES } from 'bundles/Shared/components/AgGrid/Table/classNames';
import { cn } from '@/shared/lib/css/cn';
import { BasicAutoGroupCellRendererProps } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import { getDefaultAgGridNumberColDef } from '@/shared/lib/formatting/table';
import { GlobalLeaseTableWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import React from 'react';
import { SetFilterComponent } from 'bundles/Shared/components/AgGrid/Table/filters/SetFilterComponent';
import { startCase } from 'lodash-es';

export const GLOBAL_LEASE_TABLE_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 13,
    maxH: 12,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Global Lease Table',
  icon: 'table',
  Component: GlobalLeaseTableWidget,
} as const satisfies WidgetConfiguration;

export const UNIT_COL_ID = '7';
export const ASSET_NAME_ID = '6';
export const LEASE_TYPE_ID = '3';
export const UNIT_TYPE_ID = '5';

export const getGlobalLeaseColumnDefs = (
  filterOptions: GlobalLeaseTableWidgetDto['filterOptions'],
) => {
  const getFilterOptions = (column: string) =>
    filterOptions?.find((o) => o.column === column)?.options;
  return {
    columnDefs: [
      {
        groupId: 'general',
        headerName: 'General',
        headerGroupComponentParams: {
          style: {
            backgroundColor: 'rgba(11, 23, 48, 1)',
            borderColor: 'rgba(38, 51, 79, 1)',
          },
        },
        children: [
          {
            headerName: 'Trade Out',
            colId: '0',
          },
          {
            headerName: 'Prior Rent',
            colId: '1',
          },
          {
            headerName: 'Current Rent',
            colId: '2',
          },
          {
            headerName: 'Lease Type',
            colId: LEASE_TYPE_ID,
            filter: SetFilterComponent,
            filterParams: {
              values: getFilterOptions('Lease Type'),
            },
            valueFormatter: (params) => startCase(params.value),
          },
          {
            headerName: 'Lease Status',
            colId: '4',
            filter: SetFilterComponent,
            filterParams: {
              values: getFilterOptions('Lease Status'),
            },
            valueFormatter: (params) => startCase(params.value),
          },
        ],
      },
      {
        groupId: 'unitDetails',
        headerName: 'Unit Details',
        headerGroupComponentParams: {
          style: {
            backgroundColor: 'rgba(11, 98, 79, 1)',
            borderColor: 'rgba(19, 137, 117, 1)',
          },
        },
        children: [
          {
            headerName: 'Unit Label',
            colId: UNIT_TYPE_ID,
            filter: SetFilterComponent,
            filterParams: {
              values: getFilterOptions('Unit Label'),
            },
          },
          {
            headerName: 'Asset',
            colId: ASSET_NAME_ID,
            filter: SetFilterComponent,
            suppressMenu: false,
            filterParams: {
              values: getFilterOptions('Asset Name'),
            },
          },
          {
            headerName: 'Unit',
            colId: UNIT_COL_ID,
            cellRendererParams: {
              linkable: true,
              classes: {
                inner: cn(CELL_CLASS_NAMES.CommonCell.inner.basic),
              },
            } satisfies BasicAutoGroupCellRendererProps,
          },
          {
            headerName: 'SQFT',
            colId: '8',
          },
        ],
      },
      {
        groupId: 'leaseDetails',
        headerName: 'Lease Details',
        headerGroupComponentParams: {
          style: {
            backgroundColor: 'rgba(3, 40, 98, 1)',
            borderColor: 'rgba(56, 83, 169, 1)',
          },
        },
        children: [
          {
            headerName: 'Resident Name',
            colId: '12',
          },
          {
            headerName: 'Lease Start',
            colId: '10',
            ...getDefaultAgGridNumberColDef({
              type: 'dateString',
            }),
          },
          {
            headerName: 'Lease Signed Date',
            colId: '9',
            ...getDefaultAgGridNumberColDef({
              type: 'dateString',
            }),
          },
          {
            headerName: 'Lease End',
            colId: '11',
            ...getDefaultAgGridNumberColDef({
              type: 'dateString',
            }),
          },
          {
            headerName: 'Term',
            colId: '13',
          },
        ],
      },
      {
        groupId: 'previousLeaseDetails',
        headerName: 'Previous Lease Details',
        headerGroupComponentParams: {
          style: {
            backgroundColor: 'rgba(3, 40, 98, 1)',
            borderColor: 'rgba(56, 83, 169, 1)',
          },
        },
        children: [
          {
            headerName: 'Resident Name',
            colId: '14',
          },
          {
            headerName: 'Lease Type',
            colId: '15',
            ...getDefaultAgGridNumberColDef({
              type: 'string',
            }),
            valueFormatter: (params) => startCase(params.value),
          },
          {
            headerName: 'Lease Start',
            colId: '16',
            ...getDefaultAgGridNumberColDef({
              type: 'dateString',
            }),
          },
          {
            headerName: 'Lease End',
            colId: '17',
            ...getDefaultAgGridNumberColDef({
              type: 'dateString',
            }),
          },
          {
            headerName: 'Term (Months)',
            colId: '18',
          },
        ],
      },
      {
        headerName: 'Filtered Trade-Out',
        colId: '19',
        cellRendererParams: {
          classes: {
            inner: cn(
              CELL_CLASS_NAMES.CommonCell.inner.basic,
              '!justify-center',
            ),
          },
        },
        cellRenderer: (params: ICellRendererParams) => (
          <BasicCellRenderer {...params}>
            <Icon
              className={params.value ? 'text-success-055' : 'text-danger-055'}
              iconName={params.value ? 'check' : 'closeSmall'}
            />
          </BasicCellRenderer>
        ),
      },
    ],
  } satisfies Partial<GridOptions>;
};
